import type { EffectCallback } from 'react'
import { useEffect } from 'react'

type Cb = () => ReturnType<EffectCallback> | Promise<void>

export function useOnMount(cb: Cb) {
  useEffect(() => {
    if (cb.constructor.name === 'AsyncFunction') {
      ;(cb() as Promise<any>).catch(() => undefined)
    } else {
      return cb() as ReturnType<EffectCallback>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
