import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { toast } from 'react-toastify'

import { useTranslations } from '@/hooks/core/useTranslations'
import { useAuth } from '@/hooks/use-auth'
import { defaultToastError } from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import type { FileRecord } from '@/utils/types/common'

import { useMarketingFormContext } from './CampaignContext'

export interface FormValues {
  id?: string
  name: string
  description: string
  type: string
  target: { value: string; label: string }[]
  targetStatus: { value: string; label: string }[]
  link: string
  startDate: string
  endDate: string
  image: FileRecord
}

export const useMarketingForm = () => {
  const { context, user } = useAuth()

  const t = useTranslations()

  const currentStatus =
    user.perfil && 'status' in user.perfil && user.perfil.status

  const {
    closeModal,
    isModalActive,
    openModal,
    closeEditModal,
    isEditModalActive,
    openEditModal,
    getCampaignId,
    campaignId,
    clearCampaignId,
  } = useMarketingFormContext()

  const queryClient = useQueryClient()

  const onSubmit = React.useCallback(
    async (data: FormValues) => {
      if (!context) return null

      return fetch
        .portal('/campaign', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: data.name,
            description: data.description,
            type: data.type,
            target: data.target,
            targetStatus: data.targetStatus || null,
            link: data.link,
            startDate: data.startDate,
            endDate: data.endDate,
            image: data.image,
          }),
        })
        .then(() => {
          toast.success(t('common.campaign-created-successfully'))
          queryClient.invalidateQueries(['getCampaigns'])
          closeModal()
        })
        .catch(defaultToastError)
    },
    [context, queryClient, closeModal, t]
  )

  const onEdit = React.useCallback(
    async (data: FormValues) => {
      if (!context) return null

      return fetch
        .portal(`/campaign/${campaignId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: data.name,
            description: data.description,
            type: data.type,
            target: data.target,
            targetStatus: data.targetStatus,
            link: data.link,
            startDate: data.startDate,
            endDate: data.endDate,
            image: data.image,
          }),
        })
        .then(() => {
          toast.success(t('common.updated-successfully'))
          queryClient.invalidateQueries(['getCampaigns'])
          closeEditModal()
        })
        .catch(defaultToastError)
    },
    [context, queryClient, closeEditModal, campaignId, t]
  )

  const onArchive = React.useCallback(
    async (activeCampaignId: string) => {
      if (!context) return null

      return fetch
        .portal(`/campaign/${activeCampaignId}`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            status: 'INACTIVE',
          }),
        })
        .then(() => {
          toast.success(t('common.archived-successfully'))
          queryClient.invalidateQueries(['getCampaigns'])
          closeEditModal()
        })
        .catch(defaultToastError)
    },
    [context, queryClient, closeEditModal, t]
  )

  const { data, isLoading } = useQuery({
    queryKey: ['getCampaigns'],
    queryFn: async ({ signal }) => {
      return fetch
        .portal<FormValues[]>('/campaigns', { signal })
        .then(({ data }) => data)
    },
  })

  const sizes = {
    banner: 'w-[400px] h-[100px]',
    toast: 'w-[500px]  h-[100px]',
  }

  return {
    onSubmit,
    onEdit,
    data,
    isLoading,
    isModalActive,
    openModal,
    closeModal,
    closeEditModal,
    isEditModalActive,
    openEditModal,
    campaignId,
    getCampaignId,
    clearCampaignId,
    onArchive,
    sizes,
    currentStatus,
    context,
  }
}
