import Link from 'next/link'
// import React from 'react'

// type DefaultAnchorProps = React.DetailedHTMLProps<
//   React.AnchorHTMLAttributes<HTMLAnchorElement>,
//   HTMLAnchorElement
// >

// interface LinkWithForwaredRefProps extends DefaultAnchorProps {
//   href: string
// }

// const LinkWithForwaredRef = React.forwardRef<
//   HTMLAnchorElement,
//   LinkWithForwaredRefProps
// >(({ href, children, className, ...rest }, ref) => {
//   return (
//     <Link href={href} className={className} ref={ref} {...rest}>
//       {children}
//     </Link>
//   )
// })

const LinkWithForwaredRef = Link

export default LinkWithForwaredRef
