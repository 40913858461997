'use client'

import { Transition } from '@headlessui/react'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import * as React from 'react'

import Button from '@/components/core/Button'
import { usePathname } from '@/i18n/routing'

const CookiesNote: React.FCC = () => {
  const [showing, setShowing] = React.useState(false)
  const pathname = usePathname()
  const t = useTranslations()

  const setAccepted = React.useCallback(() => {
    document.cookie = 'cookies-consent=true;path=/;'
    setShowing(false)
  }, [])

  React.useEffect(() => {
    setShowing(!document.cookie.includes('cookies-consent=true'))
  }, [])

  if (
    [
      '/iframe/simulacao/[id]',
      '/export/proposta/[idProposta]',
      '/area-cliente/agreement-session',
      '/delete-account',
      '/help',
      '/ccb/sign/[ccbId]',
    ].includes(pathname || '')
  ) {
    return null
  }

  return (
    <Transition
      appear
      show={showing}
      as={React.Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed inset-x-4 bottom-4 z-30 ml-0 items-center rounded-lg border border-light-gray-200 bg-white p-5 md:mx-auto"
        style={{
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        }}
      >
        <div className="sm:flex items-center justify-between md:flex-row">
          <p className="text-body-sm text-light-gray-500">
            {t('common.COOKIE_POLICY_DESCRIPTION')}{' '}
            <Link
              href="/politicadeprivacidade"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-primary-400 underline transition duration-300 hover:text-light-gray-400"
            >
              {t('common.COOKIE_POLICY')}
            </Link>
          </p>

          <Button
            onClick={setAccepted}
            className="button--primary min-w-max max-sm:mt-1 max-sm:float-right"
          >
            {t('common.COOKIE_POLICY_BUTTON')}
          </Button>
        </div>
      </div>
    </Transition>
  )
}

export default CookiesNote
