'use client'

import { Menu, Transition } from '@headlessui/react'
import { useTranslations } from 'next-intl'
import * as React from 'react'
import { BsBank, BsBoxes } from 'react-icons/bs'
import { FaUserTie } from 'react-icons/fa'
import { HiWrenchScrewdriver } from 'react-icons/hi2'
import { MdGroups } from 'react-icons/md'
import {
  TbCalendarDollar,
  TbLogout,
  TbSettings,
  TbSolarPanel,
  TbUserCircle,
  TbUsers,
  TbX,
} from 'react-icons/tb'
import * as ss from 'superstruct'

import LinkWithForwaredRef from '@/components/core/LinkWithForwaredRef'
import type { RenderLinksItem } from '@/components/core/RenderLinks'
import { useBreakpoint } from '@/hooks/core/useBreakpoint'
import { useAuth } from '@/hooks/use-auth'
import { getPathname } from '@/i18n/routing'
import { nomeUsuario } from '@/utils/core/etc'
import {
  c,
  firstLetters,
  isIntegradorIncompleto,
  shortenName,
  toCliente,
  toIntegrador,
} from '@/utils/etc'
import type { TipoCredencial } from '@/utils/types/structs/auth'
import { Empresa, Integrador } from '@/utils/types/structs/auth'

import AvatarMenuAvatar from './AvatarMenuAvatar'
import AvatarMenuItemInner from './AvatarMenuItemInner'
import AvatarMenuTrocarPerfil from './AvatarMenuTrocarPerfil'

const minhasInformacoesRoutes: Record<TipoCredencial, string | null> = {
  BACKOFFICE: '/area-backoffice/perfil/dados-pessoais',
  INTEGRADOR: '/area-integrador/perfil/dados-pessoais',
  DISTRIBUIDOR: '/area-distribuidor/perfil/dados-empresa',
  VENDEDOR: '/area-vendedor/perfil/dados-pessoais',
  CLIENTE: '/area-cliente/perfil/dados-pessoais',
  FINANCIADOR: null,
}

type RenderLinksItemX = RenderLinksItem & {
  Icon: React.FCC
  testID?: string
  className: string
}

const ProfilePopover: React.FC = () => {
  const { context, user, logout } = useAuth()

  const t = useTranslations()

  const shortName = React.useMemo(
    () => shortenName(nomeUsuario(user?.perfil) || '-')?.toLowerCase(),
    [user]
  )

  const nameFirstLetters = React.useMemo(
    () => firstLetters(shortName).toUpperCase(),
    [shortName]
  )

  const companyShortName = React.useMemo(() => {
    let text: string | undefined
    const { perfil } = user || {}

    if (ss.is(perfil, Integrador) && ss.is(perfil.empresa, Empresa)) {
      text = perfil.empresa.nomeFantasia || perfil.empresa.razaoSocial || ''
    }

    const cliente = toCliente(user)

    if (cliente?.empresa) {
      text = cliente.empresa.nomeFantasia || cliente.empresa.razaoSocial || ''
    }

    return shortenName(text?.toLowerCase())
  }, [user])

  const { xl, md } = useBreakpoint()

  const internalLinks = React.useMemo(() => {
    if (!context) return []

    return (
      (
        {
          BACKOFFICE: [
            {
              href: '/area-backoffice/integradores',
              label: t('menu.integrators'),
              Icon: FaUserTie,
              testID: 'notifications',
              className: `${md ? 'hidden' : ''}`,
            },
            {
              href: '/area-backoffice/distribuidores',
              label: t('menu.distributors'),
              Icon: BsBoxes,
              testID: 'notifications',
              className: `${md ? 'hidden' : ''}`,
            },
            {
              href: '/area-backoffice/financiadores',
              label: t('menu.financiers'),
              Icon: BsBank,
              testID: 'notifications',
              className: `${md ? 'hidden' : ''}`,
            },
            {
              href: '/area-backoffice/gerenciamento-usuarios',
              label: t('menu.users'),
              Icon: TbUsers,
            },
            {
              href: '/area-backoffice/settings/general',
              label: t('menu.params'),
              Icon: TbSettings,
              testID: 'settings',
            },
          ],
          INTEGRADOR: [
            {
              href: '/area-integrador/vendedores',
              label: t('menu.sellers'),
              Icon: MdGroups,
              testID: 'notifications',
              className: `${md ? 'hidden' : ''}`,
            },
          ],
          DISTRIBUIDOR: [
            {
              href: '/area-distribuidor/integradores',
              label: t('menu.integrators'),
              Icon: HiWrenchScrewdriver,
              testID: 'notifications',
              className: `${md ? 'hidden' : ''}`,
            },
            {
              href: '/area-distribuidor/vendedores',
              label: t('menu.sellers'),
              Icon: MdGroups,
              testID: 'notifications',
              className: `${md ? 'hidden' : ''}`,
            },
          ],
          CLIENTE: [
            {
              href: getPathname('/projects'),
              label: t('menu.proposals'),
              Icon: TbSolarPanel,
              testID: 'projects',
              className: `${md ? 'hidden' : ''}`,
            },
            {
              href: getPathname('/loan'),
              label: t('menu.loan'),
              Icon: TbCalendarDollar,
              testID: 'loan',
              className: `${md ? 'hidden' : ''}`,
            },
          ],
        } as Record<TipoCredencial, RenderLinksItemX[]>
      )[context] || []
    )
  }, [context, t, md])

  const roles = React.useMemo(
    () => [...new Set(user.credencial?.roles)],
    [user]
  )

  const integradorIncompleto = React.useMemo(
    () =>
      isIntegradorIncompleto(toIntegrador(user)) ||
      toIntegrador(user)?.assinaturas?.some(
        (a) => !a.signedAt && !a.contrato?.deletedAt
      ),
    [user]
  )

  if (!context) return null

  return (
    <Menu as="div" className="relative">
      {({ close }) => (
        <>
          <Menu.Button
            data-test-id="avatar-menu"
            className="flex items-center sm:space-x-4"
          >
            <AvatarMenuAvatar
              shortName={shortName}
              companyShortName={companyShortName}
              nameFirstLetters={nameFirstLetters}
              marker={integradorIncompleto}
              className="max-sm:hidden"
            />
          </Menu.Button>

          <Transition
            as={React.Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              className={c(
                'bg-white absolute right-0 z-10 rounded-lg shadow-md divide-y divide-light-gray-200 origin-top-right',
                xl ? 'mt-2 w-[240px]' : ' w-[340px] top-0'
              )}
            >
              {!xl && (
                <>
                  <section className="p-4 flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <AvatarMenuAvatar
                        shortName={shortName}
                        companyShortName={companyShortName}
                        nameFirstLetters={nameFirstLetters}
                        marker={integradorIncompleto}
                        inverse
                      />
                    </div>

                    <button onClick={close} type="button">
                      <TbX className="w-5 h-5 text-light-gray-500" />
                    </button>
                  </section>
                </>
              )}

              {internalLinks.map(
                ({ href, label, Icon, testID, className }: any) => (
                  <Menu.Item key={href}>
                    <LinkWithForwaredRef
                      data-test-id={testID}
                      className={`w-full p-2 block ${className}`}
                      href={href}
                    >
                      <AvatarMenuItemInner label={label} Icon={Icon} />
                    </LinkWithForwaredRef>
                  </Menu.Item>
                )
              )}

              {minhasInformacoesRoutes[context] && (
                <Menu.Item>
                  <LinkWithForwaredRef
                    className="p-2 w-full flex items-center relative"
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    href={minhasInformacoesRoutes[context]!}
                  >
                    <AvatarMenuItemInner
                      Icon={TbUserCircle}
                      label={t('menu.my-informations')}
                    />

                    {integradorIncompleto && (
                      <div className="pointer-events-none absolute right-4 flex h-2 w-2 items-center justify-center rounded-full bg-warning-300 p-0 text-caption-md font-bold text-warning-500" />
                    )}
                  </LinkWithForwaredRef>
                </Menu.Item>
              )}

              {roles.length > 1 && (
                <Menu.Item>
                  <>
                    <AvatarMenuTrocarPerfil
                      roles={roles.filter((c) => c !== context)}
                    />
                  </>
                </Menu.Item>
              )}

              <Menu.Item
                onClick={() => logout()}
                as="button"
                className="p-2 w-full"
              >
                <AvatarMenuItemInner label={t('menu.logout')} Icon={TbLogout} />
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default ProfilePopover
