'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useRef } from 'react'

import { c } from '@/utils/etc'

type Props = {
  links: { label: string; href: string }[]
}

export function Nav(props: Props) {
  const { links } = props

  const pathname = usePathname()

  const scrollContainerRef = useRef<HTMLSpanElement>(null)
  const leftGradient = useRef<HTMLSpanElement>(null)
  const rightGradient = useRef<HTMLSpanElement>(null)

  return (
    <nav className="flex-1 h-[calc(5rem-2px)] relative group">
      <span
        ref={scrollContainerRef}
        className="absolute inset-0 flex items-center overflow-x-auto px-4 max-md:hidden
            scrollbar scrollbar-h-1.5 group-hover:scrollbar-thumb-primary-400
            "
      >
        <ul className="flex items-center mt-1.5">
          {links.map(({ label, href }, i) => {
            const isActive = pathname?.startsWith(href)
            return (
              <li key={i}>
                <Link
                  href={href}
                  className={c(
                    'button px-3 py-2.5 cursor-pointer select-none text-white hover:bg-primary-400 hover:[text-shadow:0px_0px_1px_white]',
                    !isActive && 'hover:bg-opacity-20',
                    isActive &&
                      'bg-primary-400 bg-opacity-30 [text-shadow:0px_0px_1px_white] hover:bg-opacity-30'
                  )}
                >
                  {label}
                </Link>
              </li>
            )
          })}
        </ul>
      </span>
      <span
        ref={leftGradient}
        style={{ visibility: 'hidden' }}
        className="absolute inset-y-0 w-[50px] left-0 bg-gradient-to-r from-primary-300 pointer-events-none"
      />
      <span
        ref={rightGradient}
        style={{ visibility: 'hidden' }}
        className="absolute inset-y-0 w-[50px] right-0 bg-gradient-to-l from-primary-300 pointer-events-none"
      />
    </nav>
  )
}
