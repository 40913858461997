'use client'

import { FloatingPortal } from '@floating-ui/react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { TbX } from 'react-icons/tb'

import { useMarketingForm } from '@/components/backoffice/Marketing/CampaignModal.hook'

export function MarketingToast() {
  const { data: marketingData, currentStatus, context } = useMarketingForm()

  const [filteredData, setFilteredData] = React.useState(marketingData)

  React.useEffect(() => {
    if (marketingData) {
      setFilteredData(marketingData)
    }
  }, [marketingData])

  const removeToast = (id: string) => {
    setFilteredData((prevData) =>
      prevData?.filter((item: any) => item.id !== id)
    )
  }

  const toasts = filteredData
    ? filteredData.filter((item: any) => {
        const isTargetValid =
          item.target.includes('todos') ||
          context === 'BACKOFFICE' ||
          item.target.includes(context)
        const isStatusValid = currentStatus
          ? item.targetStatus.includes(currentStatus)
          : true
        return item.type === 'toast' && isTargetValid && isStatusValid
      })
    : []
  return (
    <FloatingPortal>
      <div className="fixed z-50 inset-4 flex flex-col items-end justify-end pointer-events-none">
        <ul className="pointer-events-auto overflow-y-auto max-h-screen flex flex-col gap-y-4 justify-end">
          {toasts.map((item: any) => (
            <div
              key={item.id}
              className="rounded-tr-md rounded-tl-md overflow-hidden relative w-[500px] h-[200px]"
            >
              <Link href={item.ctaLink} className="w-full h-full block">
                <Image
                  src={item.image.url}
                  alt={item.name}
                  quality={100}
                  width={500}
                  height={200}
                  className="object-cover w-full h-full"
                />
              </Link>
              <button
                onClick={() => removeToast(item.id)}
                className="w-6 h-6 flex items-center rounded-bl-lg justify-center shadow-md shadow-dark bg-danger-300 hover:bg-danger-500 absolute top-0 right-0"
              >
                <TbX className="text-light-gray-50" />
              </button>
            </div>
          ))}
        </ul>
      </div>
    </FloatingPortal>
  )
}
