import React from 'react'

interface AvatarMenuItemInnerProps {
  Icon: React.FCC<any>
  label: string
}

const AvatarMenuItemInner: React.FCC<AvatarMenuItemInnerProps> = ({
  label,
  Icon,
}) => {
  return (
    <span className="p-2 flex items-center space-x-2 hover:bg-primary-100 rounded-md flex-1">
      <Icon className="w-6 h-6 text-primary-300" />
      <p className="text-dark-gray-500">{label}</p>
    </span>
  )
}

export default AvatarMenuItemInner
