import { useTranslations } from 'next-intl'
import React from 'react'

import { useAuth } from '@/hooks/use-auth'
import { c } from '@/utils/etc'

interface AvatarMenuAvatarProps {
  shortName?: string
  companyShortName?: string
  nameFirstLetters?: string
  marker?: boolean
  inverse?: boolean
  className?: string
}

const AvatarMenuAvatar: React.FCC<AvatarMenuAvatarProps> = ({
  shortName,
  companyShortName,
  nameFirstLetters,
  inverse,
  marker,
  className,
}) => {
  const { context } = useAuth()

  const t = useTranslations()

  const icon = React.useMemo(
    () => (
      <div
        className={c(
          'w-12 h-12 bg-white text-primary-300 font-bold grid place-items-center rounded-full relative',
          inverse && 'border-2 border-light-gray-400'
        )}
      >
        {marker && (
          <div className="absolute right-0.5 top-0.5 flex h-2.5 w-2.5 items-center justify-center rounded-full bg-warning-300 p-0 text-caption-md font-bold text-warning-500" />
        )}

        {nameFirstLetters}
      </div>
    ),
    [inverse, marker, nameFirstLetters]
  )

  return (
    <>
      {inverse && icon}

      <div className={c(className, inverse ? 'text-left' : 'text-right')}>
        <h4
          className={c(
            'capitalize text-title-xxs font-bold whitespace-nowrap0',
            inverse ? 'text-left' : 'text-right text-white'
          )}
        >
          {shortName}
        </h4>
        <h5
          className={c(
            'capitalize text-body-sm font-medium whitespace-nowrap',
            inverse ? 'text-left' : 'text-right text-white'
          )}
        >
          {context === 'BACKOFFICE' && t('register.backoffice')}
          {context === 'INTEGRADOR' &&
            (companyShortName ||
              t('common.proposta-funding-responsaveis.integrador'))}
          {context === 'DISTRIBUIDOR' &&
            t('common.proposta-funding-responsaveis.distribuidor')}
          {context === 'VENDEDOR' &&
            t('common.proposta-funding-responsaveis.vendedor')}
          {context === 'CLIENTE' &&
            (companyShortName || t('proposal.table_customers'))}
          {context === 'FINANCIADOR' && t('register.financier')}
        </h5>
      </div>

      {!inverse && icon}
    </>
  )
}

export default AvatarMenuAvatar
