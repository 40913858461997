const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}', './pages/**/*.{tsx,ts}'],
  theme: {
    colors: {
      black: '#000000',
      white: '#ffffff',
      transparent: 'transparent',
      'dark-gray': {
        100: '#424E66',
        200: '#3A4459',
        300: '#262F40',
        400: '#19202E',
        500: '#11151F',
      },
      'light-gray': {
        50: 'white',
        100: '#F5F7FA',
        200: '#EBEFF5',
        300: '#DDE3ED',
        400: '#B8C6D9',
        500: '#738399',
      },
      primary: {
        100: '#F0F9FF',
        200: '#D1ECFF',
        300: '#3085E8',
        400: '#004A80',
        500: '#062840',
      },
      secondary: {
        100: '#EBFFF5',
        200: '#CEF2E0',
        300: '#2E9963',
        400: '#22734B',
        500: '#165938',
      },
      success: {
        100: '#E5FFF1',
        200: '#C8FADF',
        300: '#2DC573',
        400: '#11A656',
        500: '#0B733C',
      },
      danger: {
        100: '#FFF3F4',
        200: '#FCD2D2',
        300: '#DC393A',
        400: '#CC2D2E',
        500: '#B21B1C',
      },
      warning: {
        100: '#FFF8EB',
        200: '#FEEBCB',
        300: '#FFA526',
        400: '#F2920C',
        500: '#CC5500',
      },
    },
    fontFamily: {
      sans: ['Montserrat', ...defaultTheme.fontFamily.sans],
      serif: ['Montserrat', ...defaultTheme.fontFamily.serif],
      mono: ['Chivo Mono', ...defaultTheme.fontFamily.mono],
    },
    fontSize: {
      'caption-sm': ['10px', '16px'],
      'caption-md': ['12px', '16px'],
      'caption-lg': ['14px', '24px'],
      'body-sm': ['14px', '24px'],
      'body-md': ['16px', '24px'],
      'body-lg': ['18px', '32px'],
      'title-xxs': [
        '16px',
        {
          letterSpacing: '-.5px',
          lineHeight: '24px',
        },
      ],
      'title-xs': [
        '20px',
        {
          letterSpacing: '-.5px',
          lineHeight: '32px',
        },
      ],
      'title-sm': [
        '24px',
        {
          letterSpacing: '-.5px',
          lineHeight: '32px',
        },
      ],
      'title-md': [
        '32px',
        {
          letterSpacing: '-1px',
          lineHeight: '40px',
        },
      ],
      'title-lg': [
        '40px',
        {
          letterSpacing: '-1.5px',
          lineHeight: '56px',
        },
      ],
      'title-xl': [
        '48px',
        {
          letterSpacing: '-1.5px',
          lineHeight: '64px',
        },
      ],
      'title-xxl': [
        '64px',
        {
          letterSpacing: '-1.8px',
          lineHeight: '88px',
        },
      ],
      'display-sm': [
        '56px',
        {
          letterSpacing: '-1.5px',
          lineHeight: '72px',
        },
      ],
      'display-md': [
        '72px',
        {
          letterSpacing: '-1.8px',
          lineHeight: '88px',
        },
      ],
      'display-lg': [
        '96px',
        {
          letterSpacing: '-2.25px',
          lineHeight: '104px',
        },
      ],
    },
    extend: {
      scale: { header: '5rem' },
      space: { header: '5rem' },
      margin: { header: '5rem' },
      padding: { header: '5rem' },
      inset: { header: '5rem' },
      backgroundImage: {
        'slide-1': "url('/img/home-slide-1.jpg')",
        'slide-2': "url('/img/home-slide-2.jpg')",
        'slide-3': "url('/img/home-slide-3.jpg')",
        esg: "url('/img/bg-esg.jpg')",
        'header-logo':
          'linear-gradient(116deg, rgba(240,249,255,1) 150px, rgba(255,255,255,1) 150px)',
        noticias:
          'linear-gradient(180deg, #F0F9FF 0%, rgba(240, 249, 255, 0) 100%)',
        login: "url('/img/bg-login.jpg')",
      },
      height: {
        0.75: '0.1875rem',
        header: '5rem',
      },
      minHeight: {
        'slide-desk': '718px',
        'slide-mobile': '485px',
        'card-vantagens': '280px',
        'content-materia': '140px',
        'icon-plus': '24px',
      },
      minWidth: {
        'icon-plus': '24px',
      },
      maxWidth: {
        'p-sobre': '624px',
        'p-esg': '848px',
      },
      animation: {
        'spin-speed': 'spin 0.7s linear infinite',
        'spin-reverse': 'spin-reverse 1s linear infinite',
        linearBarReverse: 'linearBar 12s linear forwards reverse',
        'pulse-speed-skeleton':
          'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        'spin-animate': 'spin-animate 1.25s forwards',
      },
      keyframes: {
        linearBar: {
          '0%': { width: 0 },
          '100%': { width: '100%' },
        },
        'spin-reverse': { to: { transform: 'rotate(-360deg)' } },
        linearBarReverse: {
          '0%': { width: '0' },
          '100%': { width: '100%' },
        },
        'spin-animate': {
          '0%': { transform: 'rotate(0deg)' },
          '50%': { transform: 'rotate(60deg)' },
          '75%': { transform: 'rotate(-25deg)' },
          '100%': { transform: 'rotate(0deg)' },
        },
      },
      screens: {
        'max-xxs': { raw: '(max-width: 480px)' },
        'max-xs': { raw: '(max-width: 560px)' },
        'max-sm': { raw: '(max-width: 640px)' },
        'max-md': { raw: '(max-width: 768px)' },
        'max-lg': { raw: '(max-width: 1024px)' },
        'max-xl': { raw: '(max-width: 1280px)' },
      },
    },
  },
  plugins: [
    require('tailwindcss-scoped-groups')({
      groups: ['one', 'two', 'three'],
    }),
    require('tailwind-scrollbar')({
      nocompatible: true,
    }),
    function ({ addUtilities }) {
      addUtilities({
        '.scrollbar-hide': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
        '.scrollbar-hide::-webkit-scrollbar': {
          display: 'none',
        },
      })
    },
  ],
  variants: {
    scrollbar: ['rounded'],
  },
}
