import * as ss from 'superstruct'

import { DBRow } from './common'

// ---------------------------------------------------------------------------

export const Concessionaria = ss.assign(
  DBRow,
  ss.object({
    nome: ss.string(),
    sigla: ss.string(),
    acessos: ss.optional(ss.nullable(ss.array(ss.any()))),
  })
)
export type Concessionaria = ss.Infer<typeof Concessionaria>

// ---------------------------------------------------------------------------

export const AcessoConcessionaria = ss.assign(
  DBRow,
  ss.object({
    login: ss.string(),
    senha: ss.string(),
    concessionariaId: ss.string(),
    integradorId: ss.string(),
    concessionaria: ss.optional(ss.nullable(Concessionaria)),
  })
)
export type AcessoConcessionaria = ss.Infer<typeof AcessoConcessionaria>

// ---------------------------------------------------------------------------

export const TipoEquipamento = ss.enums(['INVERSOR', 'PLACA'])
export type TipoEquipamento = ss.Infer<typeof TipoEquipamento>

export const Equipamento = ss.assign(
  DBRow,
  ss.object({
    nome: ss.string(),
    tipo: TipoEquipamento,
  })
)
export type Equipamento = ss.Infer<typeof Equipamento>

// ---------------------------------------------------------------------------

export const ContractTemplate = ss.object({
  id: ss.string(),
  name: ss.string(),
  type: ss.enums(['OPTIN', 'CLICKSIGN']),
  model: ss.optional(ss.any()),
  variables: ss.nullable(ss.any()),
  templateFile: ss.object({
    id: ss.string(),
    name: ss.string(),
    url: ss.string(),
  }),
  createdAt: ss.string(),
  updatedAt: ss.string(),
  isContractorAgreementTemplate: ss.boolean(),
})
export type ContractTemplate = ss.Infer<typeof ContractTemplate>

// --------------------------------------------------------------------------------------------

export const ContatoFundingCompareType = ss.partial(
  ss.object({
    nome: ss.string(),
    telefone: ss.string(),
    email: ss.string(),
  })
)

export type ContatoFundingCompareType = ss.Infer<
  typeof ContatoFundingCompareType
>
