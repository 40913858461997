import type { MustHaveProps } from '@/hocs/with-form'
import { c } from '@/utils/etc'

export interface RadioInputProps extends MustHaveProps {
  name: string
  readOnly?: boolean
  checked?: boolean
  error?: boolean
  disabled?: boolean
  highlightWhenSelected?: boolean
  labelClassName?: string
  label: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const RadioInput: React.FCC<RadioInputProps> = ({
  name,
  value,
  checked,
  disabled,
  readOnly,
  label,
  containerClassName,
  className,
  inputWrapperClassName,
  passthrough,
  onBlur,
  onChange,
  onFocus,
  labelClassName,
  highlightWhenSelected = true,
}) => {
  return (
    <div className={containerClassName}>
      <label
        className={c(
          'flex select-none items-center space-x-3 rounded-md border px-3 h-10',
          disabled
            ? 'cursor-not-allowed border-light-gray-200'
            : 'cursor-pointer border-light-gray-400',
          checked &&
            highlightWhenSelected &&
            'border-primary-300 bg-primary-100',
          // : 'border-light-gray-400',
          inputWrapperClassName
        )}
      >
        <input
          type="radio"
          readOnly={readOnly}
          name={name}
          value={value as never}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          {...passthrough}
          className={c(
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            className
          )}
        />
        <p
          className={c(
            'font-medium text-body-sm',
            disabled ? 'text-light-gray-500' : 'text-dark-gray-500',
            labelClassName
          )}
        >
          {label}
        </p>
      </label>
    </div>
  )
}

export default RadioInput
