import { Disclosure } from '@headlessui/react'
import React from 'react'
import { TbArrowsRightLeft, TbChevronRight } from 'react-icons/tb'

import { getPathname } from '@/i18n/routing'
import type { TipoCredencial } from '@/utils/types/structs/auth'

import AvatarMenuItemInner from './AvatarMenuItemInner'

interface AvatarMenuTrocarPerfilProps {
  roles: TipoCredencial[]
}

const AvatarMenuTrocarPerfil: React.FCC<AvatarMenuTrocarPerfilProps> = ({
  roles,
}) => {
  return (
    <Disclosure>
      <Disclosure.Button className="p-2 w-full">
        <AvatarMenuItemInner
          label="Trocar de perfil"
          Icon={TbArrowsRightLeft}
        />
      </Disclosure.Button>

      <Disclosure.Panel className="p-2">
        {roles.map((role) => (
          <button
            key={role}
            onClick={() => location.assign(getPathname('/projects'))}
            className="w-full text-primary-300"
          >
            <span className="flex items-end justify-between pl-10 pr-2 py-2 hover:bg-primary-100 rounded-md">
              <p className="capitalize [color:inherit]">{role.toLowerCase()}</p>
              <TbChevronRight className="w-5 h-5" />
            </span>
          </button>
        ))}
      </Disclosure.Panel>
    </Disclosure>
  )
}

export default AvatarMenuTrocarPerfil
