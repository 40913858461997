import type { GetServerSidePropsContext } from 'next'
import type { NextRequest } from 'next/server'

import * as fetch from '@/utils/fetch'

import { ACCESS_TOKEN_COOKIE } from './constants'
import type { MeResponseDto } from './types/api/portal/dto'
import type { TipoCredencial } from './types/structs/auth'

type AnyRequest = GetServerSidePropsContext['req'] | NextRequest

function getCookieFromAnyRequest(req: AnyRequest, name: string) {
  if (typeof req.cookies.get === 'function') {
    return req.cookies.get(name)?.value
  }

  if (typeof (req.cookies as any)?.[name] === 'string') {
    return (req.cookies as any)[name]
  }
}

export function parseCookies(cookiesStr: string) {
  return cookiesStr.split('; ').reduce((acm, crr) => {
    const [name, value] = crr.split('=')
    return {
      ...acm,
      [name]: value,
    }
  }, {} as Record<string, string>)
}

export function getJwtToken(req?: AnyRequest): string | undefined {
  if (typeof document !== 'undefined') {
    return parseCookies(document.cookie)[ACCESS_TOKEN_COOKIE]
  } else if (req) {
    return getCookieFromAnyRequest(req, ACCESS_TOKEN_COOKIE)
  } else {
    return undefined
  }
}

export function parseJwt(jwt: string): {
  header?: Record<string, any>
  payload?: Record<string, any>
  signature?: Record<string, any>
} {
  const [header, payload, signature] = jwt.split('.').map((base64) => {
    try {
      return JSON.parse(Buffer.from(base64, 'base64').toString())
    } catch {
      return {}
    }
  })
  return { header, payload, signature }
}

export function parseSessionFromAuthMe(data: MeResponseDto) {
  let profile

  const {
    integrador,
    distribuidor,
    cliente,
    backoffice,
    funding,
    vendedor,
    ...credential
  } = data

  switch (credential?.roles[0]) {
    case 'INTEGRADOR': {
      profile = integrador
      break
    }
    case 'DISTRIBUIDOR': {
      profile = distribuidor
      break
    }
    case 'CLIENTE': {
      profile = cliente
      break
    }
    case 'BACKOFFICE': {
      profile = backoffice
      break
    }
    case 'FINANCIADOR': {
      profile = funding
      break
    }
    case 'VENDEDOR': {
      profile = vendedor
      break
    }
  }

  return {
    profile,
    user: {
      role: credential.roles?.[0],
      department: credential.department || null,
    },
    credential,
  }
}

export interface Session {
  id: string
  role: TipoCredencial
  name: string | null
  email: string | null
  ip: string | null
  department: string | null
  credentialId: string
  impersonator: null | Session
  org: null | {
    id: string
    status: string
    role: string
  }
}

export const getServerSession = async (req?: AnyRequest) => {
  return await fetch
    .portal<Session>('/auth/session', { req })
    .then(({ data }) => data)
    .catch(() => null)
}
