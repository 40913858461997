'use client'

import {
  autoUpdate,
  FloatingPortal,
  offset,
  shift,
  useFloating,
  useTransitionStyles,
} from '@floating-ui/react'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { usePostHog } from 'posthog-js/react'
import React from 'react'
import { TbInfoCircleFilled, TbXboxX } from 'react-icons/tb'

import { useMarketingForm } from '@/components/backoffice/Marketing/CampaignModal.hook'
import { useOnMount } from '@/hooks/core/useOnMount'

export function MarketingBanner() {
  const scrollContainerRef = React.useRef<HTMLSpanElement>(null)
  const leftGradient = React.useRef<HTMLSpanElement>(null)
  const rightGradient = React.useRef<HTMLSpanElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const posthog = usePostHog()

  const {
    floatingStyles,
    refs,
    context: floatingContext,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      shift(),
      offset({
        mainAxis: 10,
        crossAxis: -10,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const { isMounted, styles } = useTransitionStyles(floatingContext)

  React.useEffect(() => {
    const scroll = scrollContainerRef.current
    const left = leftGradient.current
    const right = rightGradient.current

    const updateVisibility = () => {
      if (!scroll || !left || !right) return

      left.style.visibility = scroll.scrollLeft > 0 ? 'visible' : 'hidden'
      right.style.visibility =
        scroll.scrollLeft + scroll.clientWidth < scroll.scrollWidth
          ? 'visible'
          : 'hidden'
    }

    if (scroll) {
      scroll.addEventListener('scroll', updateVisibility)
    }

    return () => {
      if (scroll) {
        scroll.removeEventListener('scroll', updateVisibility)
      }
    }
  }, [])

  const { data: marketingData, currentStatus, context } = useMarketingForm()

  const verifyBanner = marketingData
    ? marketingData.filter((item: any) => {
        const isTargetValid =
          item.target.includes('todos') ||
          context === 'BACKOFFICE' ||
          item.target.includes(context)
        const isStatusValid = currentStatus
          ? item.targetStatus.includes(currentStatus)
          : true
        return item.type === 'banner' && isTargetValid && isStatusValid
      })
    : []

  useOnMount(() => {
    const _clickAnnouncements = localStorage.getItem('clickAnouncements')
    setIsOpen(!_clickAnnouncements || _clickAnnouncements === 'open')
  })

  React.useEffect(() => {
    posthog.capture('clickAnouncements', {
      action: isOpen ? 'open' : 'close',
    })
    localStorage.setItem('clickAnouncements', isOpen ? 'open' : 'close')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (verifyBanner.length === 0) return null

  return (
    <>
      <motion.button
        ref={refs.setReference}
        animate={{
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 0.6,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'loop',
        }}
        className="flex items-center justify-center bg-primary-400 bg-opacity-30 rounded-full shadow-lg ring-1 ring-black ring-opacity-5 cursor-pointer w-12 h-12 mt-[-5px]"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <TbInfoCircleFilled size={25} className="text-white" />
      </motion.button>

      {isMounted && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...styles }}
            className="overflow-y-auto max-h-[90vh] z-50"
          >
            <div
              className="w-10 h-10 bg-light-gray-200 rounded-2xl z-40 flex justify-center items-center mb-1 border-2 border-light-gray-400 shadow-2xl cursor-pointer mx-auto"
              onClick={() => setIsOpen(false)}
            >
              <TbXboxX className="text-primary-300 text-3xl" />
            </div>
            {verifyBanner.map((item: any) => (
              <Link
                key={item.id}
                href={item?.ctaLink}
                target="_blank"
                className="mb-3 block"
                onClick={() => {
                  posthog.capture('clickedAnouncementsLinkBanner', {
                    bannerId: item.name,
                  })
                }}
              >
                <Image
                  src={item.image.url}
                  alt={item.name}
                  quality={100}
                  width={500}
                  height={200}
                  className="rounded-lg w-[500px] h-[200px] object-cover drop-shadow-xl"
                />
              </Link>
            ))}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
