'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useSearchParams } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { FormInput } from '@/components/core/FormInput'
import { FormPassword } from '@/components/core/FormPassword'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Link, useRouter } from '@/i18n/routing'
import { defaultToastError } from '@/utils/etc'
import * as fetch from '@/utils/fetch'

const FormSchema = z.object({
  email: z.string().min(2, {
    message: 'Username must be at least 2 characters.',
  }),
  password: z.string().min(2, {
    message: 'Username must be at least 2 characters.',
  }),
})

export function LoginForm() {
  const t = useTranslations('LoginForm')
  const { push } = useRouter()
  const searchParams = useSearchParams()

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  // Get isSubmitting from formState
  const { isSubmitting } = form.formState

  const { mutateAsync: login } = useMutation({
    mutationFn: async (values: { email: string; password: string }) => {
      return await fetch.portal('/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...values }),
        credentials: 'include',
      })
    },
    onSuccess: () => {
      const callbackUrl = searchParams?.get('callbackUrl') || '/projects'
      push(callbackUrl as never)
    },
    onError: (err: Error) => {
      if (err?.message !== 'challenge-closed') {
        defaultToastError(err)
      }
    },
  })

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      await login(data)
    } catch (error) {
      defaultToastError(error)
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full px-6 sm:px-0 sm:w-2/3 space-y-4"
      >
        <FormInput control={form.control} name="email" label={t('email')} />
        <FormPassword
          control={form.control}
          name="password"
          label={t('password')}
          renderAfterLabel={() => (
            <Link
              className="text-caption-lg mb-0.5"
              href="/login/esqueci-senha"
            >
              {t('forgotPassword')}
            </Link>
          )}
        />
        <Button className="w-full !mt-6" type="submit" loading={isSubmitting}>
          {t('loginBtn')}
        </Button>
      </form>
    </Form>
  )
}
