import 'react-calendar/dist/Calendar.css'

import { Popover } from '@headlessui/react'
import * as Sentry from '@sentry/nextjs'
import { useLocale } from 'next-intl'
import React from 'react'
import type { CalendarProps } from 'react-calendar'
import { Calendar } from 'react-calendar'
import { createPortal } from 'react-dom'
import { TbCalendar } from 'react-icons/tb'

import type { MustHaveProps } from '@/hocs/with-form'
import { c, dayjs } from '@/utils/etc'

import FieldInput from '../field-input'

export interface DateInputProps extends MustHaveProps {
  label?: string
  value?: string
  min?: Date
  setFilterDefinedSelected?: React.Dispatch<React.SetStateAction<string>>
  max?: Date
  disabled?: boolean
  utc?: boolean
  onChange?: (s: string) => void
}

type OnChangeDateCallback = Exclude<CalendarProps['onChange'], undefined>

const ONE_DAY_MILIS = 864e5

const DateInput: React.FC<DateInputProps> = ({
  value: _value,
  min,
  max = new Date(9999, 11, 31),
  onChange,
  setFilterDefinedSelected,
  inputWrapperClassName,
  name,
  disabled,
  utc,
  ...props
}) => {
  const lang = useLocale()

  const [open, setOpen] = React.useState<boolean>(false)

  const dateStrFormat = { en: 'MM/DD/YYYY' }[lang] || 'DD/MM/YYYY'
  const placeholder = { en: 'mm/dd/yyyy' }[lang] || 'dd/mm/aaaa'

  const inputRef = React.useRef<HTMLInputElement>(null)
  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const calendarOnChange = React.useCallback<OnChangeDateCallback>(
    (value: any) => {
      setFilterDefinedSelected && setFilterDefinedSelected('')
      if (Array.isArray(value) || value === null) {
        return onChange?.call(undefined, '')
      }
      onChange?.call(undefined, value.toISOString())
    },
    [onChange, setFilterDefinedSelected]
  )

  const inputOnChange = React.useCallback(
    (v: string) => {
      if (v.length !== placeholder.length) {
        onChange && onChange('')
        return
      }
      const parsed = dayjs(v, dateStrFormat)
      if (!parsed.isValid()) {
        onChange && onChange('')
        return
      }

      onChange && onChange(parsed.toISOString())
    },
    [dateStrFormat, onChange, placeholder]
  )

  const getCalendarPosition = React.useCallback(() => {
    const trigger = triggerRef.current
    if (!trigger) return { hidden: true }
    const { x, y, width, height } = trigger.getBoundingClientRect()
    if (y + 290 > window.innerHeight) {
      return { left: x + width - 350, top: y - 290 }
    } else {
      return { left: x + width - 350, top: y + height }
    }
  }, [])

  const value = React.useMemo(() => {
    if (!_value) return
    try {
      const parsed = dayjs(_value).tz(utc ? 'UTC' : undefined)
      if (isNaN(+parsed.toDate())) return
      return parsed.format(dateStrFormat)
    } catch {
      // ignored
    }
  }, [_value, dateStrFormat, utc])

  const calendarValue = React.useMemo(() => {
    try {
      const parsed = dayjs(value, dateStrFormat)
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const date = parsed.toDate()
      if (
        isNaN(+date) ||
        date.getFullYear() < 1900 ||
        date.getFullYear() > currentYear + 1
      ) {
        return undefined
      }
      return date
    } catch (err) {
      Sentry.captureMessage('date parse error', {
        extra: { value },
      })
      return undefined
    }
  }, [dateStrFormat, value])

  const maskOptions = React.useMemo(
    () => ({
      min: min && new Date(+min - ONE_DAY_MILIS),
      max,
    }),
    [min, max]
  )

  return (
    <>
      <FieldInput
        {...props}
        disabled={disabled}
        name={name}
        ref={inputRef}
        type="date"
        value={value}
        locale={lang}
        placeholder={placeholder}
        onChange={inputOnChange}
        inputWrapperClassName={c(
          'relative flex items-center',
          inputWrapperClassName
        )}
        maskOptions={maskOptions}
      >
        {!disabled && (
          <div slot="after" className="absolute right-4">
            <Popover className="relative">
              <Popover.Button
                ref={triggerRef}
                disabled={disabled}
                as="label"
                htmlFor={`${name}-f`}
                onClick={() => setOpen(true)}
              >
                <TbCalendar className="w-5 h-5 text-primary-300" />
              </Popover.Button>

              {open && (
                <>
                  <div
                    className="fixed inset-0 z-10"
                    onClick={() => setOpen(false)}
                  ></div>
                  <Popover.Panel
                    className="absolute z-20 -right-6"
                    static={true}
                  >
                    {createPortal(
                      <span
                        style={{ ...(open && getCalendarPosition()) }}
                        className="fixed z-50 pointer-events-auto"
                      >
                        <Sentry.ErrorBoundary
                          beforeCapture={() => {
                            Sentry.captureMessage('date parse error', {
                              extra: { calendarValue },
                            })
                          }}
                          fallback={<></>}
                        >
                          <Calendar
                            value={calendarValue}
                            onChange={calendarOnChange}
                            maxDate={max}
                            minDate={min}
                          />
                        </Sentry.ErrorBoundary>
                      </span>,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      document.querySelector('body')!
                    )}
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
        )}
      </FieldInput>
    </>
  )
}

export default DateInput
