import { useTranslations as useNextIntlTranslations } from 'next-intl'
import React from 'react'

import en from '@/../messages/en.json'

export function useTranslations(prefix?: string) {
  const _t = useNextIntlTranslations()

  const t = React.useCallback(
    (path: string, variables?: any) => {
      if (
        typeof path === 'string' &&
        Object.keys(en).some((namespace) => path.startsWith(namespace))
      ) {
        return _t(path, variables)
      } else {
        return _t([prefix, path].filter(Boolean).join('.'), variables)
      }
    },
    [_t, prefix]
  )

  return t
}
