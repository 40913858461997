import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslations } from 'next-intl'
import { usePostHog } from 'posthog-js/react'
import React from 'react'
import { Form } from 'react-final-form'
import { TbX } from 'react-icons/tb'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import * as Input from '@/components/core/input'
import { env } from '@/utils/envs'
import { defaultToastError, validateFormValues } from '@/utils/etc'
import * as fetch from '@/utils/fetch'

import Button from './Button/Button'
import PDFImagePreview from './input/MultipleFile/PDFImagePreview'
import BaseModal from './Modals/BaseModal/BaseModal'

type OptinPayload = {
  type: 'OPTIN'
  data: { name: string; url: string; signer?: string }
}

type ClicksignPayload = {
  type: 'CLICKSIGN'
  data: Record<string, any>
}

type PendingContract = { id: string } & (OptinPayload | ClicksignPayload)

const ContractSigner: React.FC = () => {
  const t = useTranslations()

  const posthog = usePostHog()

  const queryClient = useQueryClient()

  const { data: contract } = useQuery({
    queryKey: ['/contrato/pending'],
    queryFn: async ({ signal }) => {
      return await fetch
        .portal<PendingContract>('/contrato/pending', {
          signal,
        })
        .then(({ data }) => data)
    },
  })

  const { mutateAsync: sign } = useMutation({
    mutationFn: async (id: string) =>
      await fetch.portal(`/contrato/${id}/sign`, {
        method: 'PATCH',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['/contrato/pending'])
      queryClient.invalidateQueries(['me'])
      toast.success(t('common.updated-successfully'))
    },
    onError: defaultToastError,
  })

  React.useEffect(() => {
    if (contract) {
      posthog.capture('contractShown', {
        contract,
      })
      if (contract.type === 'CLICKSIGN') {
        verifyContractoClicksign()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract])

  if (!contract) return null

  const request_signature_id = contract.data.signer
  function verifyContractoClicksign() {
    let widget: any = null
    if (widget) {
      widget.unmount()
    }

    widget = new Clicksign(request_signature_id)

    widget.endpoint = env.CLICKSIGN_URL
    widget.origin = window.origin
    widget.mount('container')

    widget.on('loaded', function () {
      console.log('loaded!')
    })
    widget.on('signed', function () {
      console.log('signed!')
    })
    widget.on('resized', function (event: any) {
      const container = document.getElementById('container')
      if (event && event.data && container) {
        container.style.height = event?.data?.height + 'px'
      }
    })
  }

  return (
    <>
      {contract.type === 'OPTIN' ? (
        <OptinSigner
          {...contract}
          onClose={() => {
            posthog.capture('contractClosed', {
              contract,
            })
          }}
          onSubmit={() => sign(contract.id)}
        />
      ) : (
        <ClickSigner
          {...contract}
          onClose={() => {
            posthog.capture('contractClosed', {
              contract,
            })
          }}
          onSubmit={() => sign(contract.id)}
        />
      )}
    </>
  )
}

const OptinSigner: React.FC<
  PendingContract & OptinPayload & { onClose: () => void; onSubmit: () => void }
> = (props) => {
  const { data, onSubmit, onClose } = props

  const t = useTranslations('OptinSigner')

  const [isOpen, setIsOpen] = React.useState(true)

  const validate = React.useMemo(
    () =>
      validateFormValues(
        yup.object({
          checkbox: yup
            .boolean()
            .isTrue(t('accept-true'))
            .required(t('accept-true')),
        })
      ),
    [t]
  )

  return (
    <BaseModal
      open={isOpen}
      panelClassName="self-stretch max-w-screen-lg items-start flex flex-col relative max-sm:px-4 max-sm:py-6"
    >
      <button
        type="button"
        onClick={() => {
          setIsOpen(false)
          onClose()
        }}
        className="absolute right-6 top-6"
      >
        <TbX size={24} />
      </button>
      <h1 className="text-title-sm font-bold mb-2">{data.name}</h1>
      <p className="mb-6">{t('description')}</p>
      <PDFImagePreview
        src={`/api/test-file-previewer?url=${data.url}`}
        containerClassName="bg-light-gray-100 overflow-y-auto mb-6 border border-light-gray-400"
        renderFallback={() => (
          <div className="absolute inset-0 flex items-center justify-center">
            <h3 className="text-center !text-title-xs font-medium">
              {t('error')}
            </h3>
          </div>
        )}
      />
      <Form validate={validate} onSubmit={() => onSubmit()}>
        {({ handleSubmit, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className="flex gap-2 flex-col sm:flex-row justify-between w-full"
          >
            <Input.Form.Checkbox
              name="checkbox"
              label={t('accept-checkbox', { name: data.name })}
              labelClassName="!text-dark-gray-500 sm:text-body-lg -mt-1"
            />
            <Button
              type="submit"
              loading={submitting}
              className="button--primary self-start max-sm:w-full"
            >
              {t('accept-btn')}
            </Button>
          </form>
        )}
      </Form>
    </BaseModal>
  )
}

const ClickSigner: React.FC<
  PendingContract & { onClose: () => void; onSubmit: () => void }
> = (props) => {
  const { data, onClose } = props

  const t = useTranslations('OptinSigner')

  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <BaseModal
      open={isOpen}
      panelClassName="self-stretch max-w-screen-lg items-start flex flex-col relative"
    >
      <button
        type="button"
        onClick={() => {
          setIsOpen(false)
          onClose()
        }}
        className="absolute right-6 top-6"
      >
        <TbX size={24} color="#999999" />
      </button>
      <h1 className="text-title-xxs font-bold mb-1">{data.name}</h1>
      <p className="mb-3 text-title-xxs">{t('descriptionClicksigner')}</p>
      <div id="container" style={{ width: '100%', height: '700px' }}></div>
    </BaseModal>
  )
}

export default ContractSigner
